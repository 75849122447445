//mobile

.semibold {
  font-weight: 600;
}
.white {
  color: white;
}
.black {
  color: black;
}
.blue {
  color: $blue;
}
.darkblue {
  color: $darkblue;
}
.grey {
  color: $grey;
}
.bg-darkblue {
  .item-number {
    color: white;
    &:before {
      background-color: white;
    }
  }

}
.underline {
  text-decoration: underline;
}
.txt-link {
  text-decoration: underline;
  color: $blue;
  transition: .4s;
  &:hover {
    text-decoration: underline;
    color: $purple;
    transition: .4s;
  }
}
//desktop
@include media-breakpoint-up(lg) {

}
