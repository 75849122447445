//mobile

.form-group {
  position:relative;
  margin: 24px 0;
}
.form-control, input, textarea {
  background: #FFFFFF;
  border: 1px solid $darkgreen;
  border-radius: 8px;
  outline: none !important;
  box-shadow: none !important;
  font-weight: normal;
  font-size: 1rem;
  color: $darkgrey;
  padding: 11px 16px;
  height: auto;
  line-height: normal;

  &:focus, &:active, &:hover {
    border: 1px solid $darkgreen;
  }
}

//botão de checkbox
.checkbox {
  font-weight: 500;
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: rgba(0,0,0,0);
    border: 2px solid black;
    border-radius: 4px;
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: 5px;
      top: 1px;
      width: 6px;
      height: 10px;
      border: solid white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  &:hover input ~ .checkmark {
    background-color: rgba(0,0,0,0);
  }
  input:checked ~ .checkmark {
    background-color: black;
    &:after {
      display: block;
    }
  }
  &.green {
    .checkmark {
      border: 2px solid $darkgreen;
    }
    input:checked ~ .checkmark {
      background-color: $darkgreen;
    }
  }
}


//botão de radio

.radiobox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    &:checked ~ .checkmark {
      background-color: rgba(0,0,0,0);
      &:after {
        display: block;
      }
    }
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: rgba(0,0,0,0);
    border-radius: 50%;
    box-shadow: 0px 0px 0px 2px black;
    &:after {
      content: "";
      position: absolute;
      display: none;
      top: 3.7px;
      left: 3.7px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: black;
    }
  }
  &:hover input ~ .checkmark {
    background-color: rgba(0,0,0,0);
  }
  &.green {
    .checkmark {
      box-shadow: 0px 0px 0px 2px $green;
      &:after {
        background: $green;
      }
    }
    &.small {
      .checkmark {
        height: 20px;
        width: 20px;
        &:after {
          top: 3.5px;
          left: 3px;
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  &.small {
    .checkmark {
      height: 20px;
      width: 20px;
      &:after {
        top: 3.5px;
        left: 3px;
        width: 14px;
        height: 14px;
      }
    }
  }
}


//desktop
@include media-breakpoint-up(lg) {
}
