// mobile
html {
  overflow-x: hidden;
}
html, body {
  font-family: $font-primary;
  color: $black;
  font-size: 16px;
  font-weight: 400;
  background: white;
  // touch-action: pan-y;
  // overflow-x: hidden;
}
html {
  &.hidden-overflow {
    overflow-y: hidden;
  }
}
body {
  padding-top: 80px;
}

.wrapper-contato-float:not(body.home .wrapper-contato-float) {
  display: none;
}
main {
  min-height: calc(100vh - 412px);
}

.font-2 {
  font-family: $font-secondary;
}
h1, h2, h3, h4, h5 {
  font-weight: 700;
}
h1 {
  font-size: 3.5em;
      line-height: 4rem;
}
h2 {
  font-size: 2.75em;
  line-height: 3rem;
  &.font-2 {
    font-size: 2.5em;
    line-height: 3rem;
  }
}
h3 {
  font-size: 2em;
      line-height: 2rem;
  &.font-2 {
    font-size: 1.5625em;
    line-height: 1.875rem;
  }
}
h4 {
  font-size: 1.5em; //24px
  line-height: 1.8125rem; //29px
  &.font-2 {
    font-size: 1.125em; //18px
    line-height: 1.4375rem;//23px
  }
}

h5 {
  &.font-2 {
    font-size: 0.9375em; //15px
    line-height: 1.125rem;//18px
  }
}

p {
  font-size: 1em; //16px
  line-height: 1.1875rem; //19px
}

span {
  font-size: 0.8125em; //13px
  line-height: 1rem; //16px
}

label {
  font-size: 1em; //16px
  text-transform: initial;
  font-weight: 700;
}

b, strong {
  font-weight: 700;
}

strong p {
  font-weight: 700 !important;
}
a, a:hover, a:active, a:focus {
  text-decoration: none;
  color: inherit;
}

//desktop
@include media-breakpoint-up(lg) {

  body {
    padding-top: 80px;
  }

  main {
    min-height: calc(100vh - 412px);
  }


  h1 {
    font-size: 4.06em; //65px
    line-height: 4.875rem;//78px
  }
  h2 {
    font-size: 2.8125em; //45px
    line-height: 3.375rem;//54px
    &.font-2 {
      font-size: 2.5em; //40px
      line-height: 3rem;//48px
    }
  }
  h3 {
    font-size: 2em; //32px
    line-height: 2.4375rem; //39px
    &.font-2 {
      font-size: 1.5625em; //25px
      line-height: 1.875rem;//30px
    }
  }
  h4 {
    font-size: 1.5em; //24px
    line-height: 1.8125rem; //29px
    &.font-2 {
      font-size: 1.125em; //18px
      line-height: 1.4375rem;//23px
    }
  }

  h5 {
    &.font-2 {
      font-size: 0.9375em; //15px
      line-height: 1.125rem;//18px
    }
  }


}
