//mobile
header {
  position: fixed;
  top: 0;
  left:0;
  right: 0;
  background-color: $darkblue;
  max-height: 80px;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  z-index: 3;
  // &.open-menu {
  // }
}

.logo {
  max-height: 42px;
  height: 100%;
  width: 100%;
  object-fit: contain;
}
//desktop
@include media-breakpoint-up(lg) {
}
