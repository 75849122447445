//mobile
.bg-blue {
  background-color: $blue;
}
.bg-darkblue {
  background-color: $darkblue;
  color: white;
}
.bg-rose {
  background-color: $rose;
}
.bg-darkgrey {
  background-color: $darkgrey;
  color: white;
}

.bg-gradiente {
  background: $gradiente;
}

.bg-offwhite {
  background-color: $offwhite;
}

.bg-danger {
    background-color: #FC0000!important;
}
.bg-warning {
background-color: $yellow !important;
}
//desktop
@include media-breakpoint-up(lg) {
}
