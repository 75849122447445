//mobile
.hamburger {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.box-menu {
  background: url('../images/grafismo-menu.svg') no-repeat left 80% bottom -35%/205%, $darkblue;
  position: fixed;
  left: -100%;
  top: 0;
  bottom: 0;
  width: 100%;
  transition: .6s;
  pointer-events: none;
  box-shadow: 0px 0px 22px rgba(78, 234, 226, 0.22);
  padding-top: 130px;
  &.open {
    left: 0;
    transition: .6s;
    pointer-events: auto;
  }
}
header {
  .menu {
    list-style: none;
    padding: 0;
  }
  .menu .menu-item {
    font-size: 1.5rem; //32px
    font-weight: 300;
    line-height: 32px;
    margin-bottom: 16px;
    position: relative;
    width: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    color: white;
    &.current-menu-item::after, &.current_page_item::after {
      content: ' ';
      width: 100%;
      height: 3px;
      background: $gradiente-aqua;
      display: inline-block;
      position: absolute;
      left: 0;
      bottom: -10px;
      right: 0;
      border-radius: 100px;
    }
  }
  .box-menu .widget_text {
    list-style: none;
    text-align: left;
  }

  .hamburger {
    justify-content: flex-start;
    margin-right: 30px;
  }

}
//desktop
@include media-breakpoint-up(lg) {
  header {
    .menu .menu-item {
      margin-bottom: 30px;
      font-weight: 700;
      font-size: 2rem;
      line-height: normal;
      color: white;
    }
    .box-menu {
      background: url('../images/grafismo-menu.svg') no-repeat left 80% bottom -35%/205%, $darkblue;
      left: -28.5%;
      width: 28.5%;
      padding-top: 130px;
    }

  }
}
