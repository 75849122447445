//mobile
.z-index-1 {
  z-index: 1;
}
.z-index-2 {
  z-index: 2;
}

//desktop
@include media-breakpoint-up(lg) {

}
