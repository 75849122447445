//mobile
.btn {
  font-weight: 600;
  text-transform: initial;
  padding: 10px 16px;
  // max-width: 240px;
  // width: 100%;
  border-radius: 8px;
  &.btn-outline-primary, &.btn-outline-secondary, &.btn-outline-tertiary, &.btn-outline-quaternary {
    padding: 8px 16px;
    &.small {
    padding: 4px 16px;
    }
    &.big {
    padding: 11.5px 16px;
    }
  }
  &.small {
  padding: 6px 16px;
  }
  &.big {
  padding: 13.5px 16px;
  }
}
.btn-primary {
  color: $darkblue;
  background: $gradiente-btn-primary;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  transition: .4s;
}

.btn-primary:hover, .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled):focus {
  color: $darkblue;
  background: $gradiente-btn-primary-hover;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  transition: .4s;
}

.btn-secondary {
  background: $gradiente-btn-secondary;
  border: none;
  color: white;
  outline: none !important;
  box-shadow: none !important;
}

.btn-secondary:hover, .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled):focus {
  background: $gradiente-btn-secondary-hover;
  border: none;
  color: white;
  outline: none !important;
  box-shadow: none !important;
  box-shadow: 3px 3px 7px rgba(73, 69, 255, 0.35);
}

.btn-tertiary {
  background: $gradiente-btn-tertiary;
  border: none;
  color: $darkblue;
  outline: none !important;
  box-shadow: none !important;
}

.btn-tertiary:hover, .btn-tertiary:not(:disabled):not(.disabled):active, .btn-tertiary:not(:disabled):not(.disabled):focus {
  background: $gradiente-btn-tertiary-hover;
  border: none;
  color: $darkblue;
  outline: none !important;
  box-shadow: none !important;
  box-shadow:  3px 3px 7px rgba(0, 107, 97, 0.25);
}

.btn-quaternary {
  background: $gradiente-btn-quaternary;
  border: none;
  color: white;
  outline: none !important;
  box-shadow: none !important;
}

.btn-quaternary:hover, .btn-quaternary:not(:disabled):not(.disabled):active, .btn-quaternary:not(:disabled):not(.disabled):focus {
  background: $gradiente-btn-quaternary-hover;
  border: none;
  color: white;
  outline: none !important;
  box-shadow: none !important;
  box-shadow:  3px 3px 7px rgba(0, 107, 97, 0.25);
}

.btn-outline-primary {
  border-color: $aqua;
  color: white;
  background-color: rgba(255,255,255,0);
  outline: none !important;
  box-shadow: none !important;
  border-width: 2px;
}

.btn-outline-primary:hover, .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled):focus {
  border-color: $aqua;
  color: white;
  background-color: rgba(0, 153, 144, 0.1);
  outline: none !important;
  box-shadow: none !important;
  border-width: 2px;
}
.btn-outline-secondary {
  border-color: $blue;
  color: $blue;
  background-color: rgba(255,255,255,0);
  outline: none !important;
  box-shadow: none !important;
  border-width: 2px;
}

.btn-outline-secondary:hover, .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled):focus {
  border-color: $blue;
  color: $blue;
  background-color: rgba(84, 69, 255, 0.1);
  outline: none !important;
  box-shadow: none !important;
  border-width: 2px;
}

.btn-outline-tertiary {
  border-color: $darkgreen;
  color: $darkgreen;
  background-color: rgba(255,255,255,0);
  outline: none !important;
  box-shadow: none !important;
  border-width: 2px;
}

.btn-outline-tertiary:hover, .btn-outline-tertiary:not(:disabled):not(.disabled):active, .btn-outline-tertiary:not(:disabled):not(.disabled):focus {
  border-color: $darkgreen;
  color: $darkgreen;
  background-color: rgba(0, 153, 144, 0.05);
  outline: none !important;
  box-shadow: none !important;
  border-width: 2px;
}

.btn-outline-quaternary {
  border-color: $purple;
  color: white;
  background-color: rgba(255,255,255,0);
  outline: none !important;
  box-shadow: none !important;
  border-width: 2px;
}

.btn-outline-quaternary:hover, .btn-outline-quaternary:not(:disabled):not(.disabled):active, .btn-outline-quaternary:not(:disabled):not(.disabled):focus {
  border-color: $purple;
  color: white;
  background-color: rgba(141, 131, 255, 0.1);
  outline: none !important;
  box-shadow: none !important;
  border-width: 2px;
}

.btn-lang {
  cursor: pointer;
}
.lang-mobile .box-lang li {
  list-style: none;
  display: inline-block;
  margin-right: 10px;
}


.load-more-labs {
  width: 45px;
  height: 45px;
  border: 2px solid black;
  border-radius: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: .4s;
  cursor: pointer;

  .line-h {
    background-color: black;
    height: 20px;
    width: 2px;
    position: absolute;
  }
  .line-v {
    background-color: black;
    width: 20px;
    height: 2px;
    position: absolute;
  }
  &:hover {
    transform: rotate(180deg);
    transition: .4s;
  }
}
//desktop
@include media-breakpoint-up(lg) {

  .load-more-labs {
    width: 64px;
    height: 64px;
    border: 3px solid black;
    .line-h {
      height: 39px;
      width: 3px;
    }
    .line-v {
      width: 39px;
      height: 3px;
    }
  }

}
