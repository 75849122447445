//mobile
.card {

}
.card-alert {
	border: none;
	border-radius: 0 0 5px 5px;
	color: $darkblue;
	.card-body {
		padding: 16px;
	}
	.card-header {
		border: none;
		border-radius: 10px 10px 0 0 !important;
		padding: 2.5px;
		margin-top: -5px;
	}
}
//desktop
@include media-breakpoint-up(lg) {
}
