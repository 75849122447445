//mobile
footer {
  background-color: white;
  padding: 32px 0px;
  z-index: 2;
  position: relative;
  color: $darkblue;
  .menu {
  color: $darkblue;
    padding: 0;
    list-style: none;
    font-weight: 300;
    li {
      margin-bottom: 20px;
      display: block;
      font-size: 1em; //16px
      line-height: 1.125em; //18px
      font-weight: 700;
    }
  }

  .menu-menu-footer-container {
    display: block;
    margin-right: 30px;
  }
  .copyright {
    li {
      display: inline-block;
      font-size: 0.8125em; //13px
      line-height: 1rem; //16px
      color: $darkblue;
    }
    p {
      margin-bottom: 0 !important;
    }
  }
  .termos li {
    display: inline-block;
    font-size: 0.8125em; //13px
    line-height: 1rem; //16px
    a {
      color: $purple;
      text-decoration: underline;
      transition: .4s;
      &:hover {
        color: $blue;
        transition: .4s;
      }
    }
  }
  .social-media li {
    display: inline-block;
    a {
      margin-right: 20px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  hr {
    border-color: #7D828E;
    margin: 33px 0;
  }
}
//desktop
@include media-breakpoint-up(lg) {

  footer {
      color: $grey;
    .menu {
      color: $grey;
      li {
        margin-bottom: 12px;
        display: inline-block;
        font-size: 0.875em; //14px
        line-height: 1.125em; //18px
        font-weight: 500;
        &:after {
          content: '/';
          display: inline-block;
          margin: 0 5px;
        }
        &:last-child:after {
          display: none;
        }
      }
    }
    .menu-menu-footer-container {
      display: inline-block;
      margin-right: 30px;
    }
  }
}
