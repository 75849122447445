// mobile
.container {
  max-width: calc(100% - 16px);
}
.col-custom {
  max-width: calc(100% - 62px);
  flex: 0 0 calc(100% - 62px);
}

//desktop
@include media-breakpoint-up(lg) {

  .container {
    max-width: calc(100% - 160px);
  }

}
