//mobile
.ico-arrow, .ico-download {
  height: 20px;
  width: 20px;
  display: inline-block;
  vertical-align: text-bottom;
  margin-right: 5px;
}
.btn-primary, .btn-tertiary {
  .ico-arrow {
    background: url('../images/icons/arrow.svg') no-repeat center / contain;
  }
}
.btn-secondary, .btn-quaternary  {
  .ico-arrow {
    background: url('../images/icons/arrow-light.svg') no-repeat center / contain;
  }
}

.btn-outline-primary {
  .ico-download {
    background: url('../images/icons/download.svg') no-repeat center / contain;
    transition: .4s;
  }
  &:hover .ico-download, &:not(:disabled):not(.disabled):active .ico-download, &:not(:disabled):not(.disabled):focus .ico-download {
    background: url('../images/icons/download-green.svg') no-repeat center / contain;
    transition: .4s;
  }
}


.btn-outline-secondary {
  .ico-download {
    background: url('../images/icons/download-blue.svg') no-repeat center / contain;
  }
}

.btn-outline-tertiary {
  .ico-download {
    background: url('../images/icons/download-darkgreen.svg') no-repeat center / contain;
    transition: .4s;
  }
}

.btn-outline-quaternary {
  .ico-download {
    background: url('../images/icons/download.svg') no-repeat center / contain;
    transition: .4s;
  }
  &:hover .ico-download, &:not(:disabled):not(.disabled):active .ico-download, &:not(:disabled):not(.disabled):focus .ico-download {
    background: url('../images/icons/download-purple.svg') no-repeat center / contain;
    transition: .4s;
  }
}

.social-icons {
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
}

.ico-twitter {
  background: url('../images/icons/ico-twitter.svg') no-repeat center / contain;
  transition: .4s;
  &:hover {
    background: url('../images/icons/ico-twitter-hover.svg') no-repeat center / contain;
    transition: .4s;
  }
}

.ico-facebook {
  background: url('../images/icons/ico-facebook.svg') no-repeat center / contain;
  transition: .4s;
  &:hover {
    background: url('../images/icons/ico-facebook-hover.svg') no-repeat center / contain;
    transition: .4s;
  }
}

.ico-instagram {
  background: url('../images/icons/ico-instagram.svg') no-repeat center / contain;
  transition: .4s;
  &:hover {
    background: url('../images/icons/ico-instagram-hover.svg') no-repeat center / contain;
    transition: .4s;
  }
}

.ico-cart {
  background: url('../images/icons/ico-cart.svg') no-repeat center / contain;
  width: 25px;
  height: 25px;
  display: inline-block;
  vertical-align: middle;
}

//desktop
@include media-breakpoint-up(lg) {

}
