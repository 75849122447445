//mobile

.list {
  list-style: none;
  padding: 0;
  margin-bottom: 32px;
  a {
    display: inline-block;
    position: relative;
    span {
      font-size: 1em;
    }
    &:hover {
      span {
        border-bottom: 2px solid black;
      }
    }
  }
  li {
    font-size: 2.4em; //48px
    font-weight: 300;
    line-height: normal;
    margin-bottom: 6px;
  }
}
.post-type-archive-projetos .list li {
  font-weight: bold;
}

.white {
  .list {
    a {
      &:hover {
        span {
          border-bottom: 2px solid white;
        }
      }
    }
  }
}

.list-hover {
  .li-item {
    opacity: 0;
    transition: .4s;
    pointer-events: none;
    position: absolute;
    height: 300px;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    &.hover {
      opacity: 1;
      transition: .4s;
      pointer-events: auto;
    }
  }
}
#nossos-projetos {
  .list-hover {
    position: sticky;
    top: 300px;
    height: 400px;
  }
}
#middle-servicos {
  .list {
    margin-bottom: 0;
    margin-top: 16px;
    li {
      font-size: 1rem;
      line-height: 26px;
      margin-bottom: 30px;
    }
  }
}
//desktop
@include media-breakpoint-up(lg) {

  .post-type-archive-projetos .list li {
    font-weight: normal;
  }
}
